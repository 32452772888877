import React, { useState, useEffect } from "react";
import LogoIcon from "./logo.png";
import CheckIcon from "./icon-check.png";
import SuccessIcon from "./icon-success.svg";
import "./App.css";

const phoneRegex = /^\d{10}$/;
const verificationCodeRegex = /^\d{4}$/;

const App = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [isPhoneNumberEntered, setIsPhoneNumberEntered] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerificationCodeValid, setIsVerificationCodeValid] = useState(false);
  const [isRequestProcessed, setIsRequestProcessed] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isOtpResent, setIsOtpResent] = useState(false);
  const [timer, setTimer] = useState(30);
  const [sendOTPProcessing, setSendOTPProcessing] = useState(false);
  const [verifyOTPProcessing, setVerifyOTPProcessing] = useState(false);
  const [accountDeleteProcessing, setAccountDeleteProcessing] = useState(false);

  const [sendOTPVerificationToken, setSendOTPVerificationToken] = useState("");
  const [verifyOTPVerificationToken, setVerifyOTPVerificationToken] =
    useState("");

  const [sendOTPError, setSendOTPError] = useState("");
  const [verifyOTPError, setVerifyOTPError] = useState("");
  const [accountDeleteError, setAccountDeleteError] = useState("");

  // Timer for OTP resend
  useEffect(() => {
    let intervalId;
    if (isOtpResent && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsOtpResent(false);
      setTimer(30);
    }
    return () => clearInterval(intervalId);
  }, [isOtpResent, timer]);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    if (phoneRegex.test(value)) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    setVerificationCode(value);
    if (verificationCodeRegex.test(value)) {
      setIsVerificationCodeValid(true);
    } else {
      setIsVerificationCodeValid(false);
    }
  };

  const sendOTP = async () => {
    setSendOTPProcessing(true);
    setSendOTPError("");
    setVerifyOTPError("");
    try {
      const response = await fetch("https://api.linkyards.in/auth/sendotp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: phoneNumber,
          country: {
            name: "India",
            code: "IN",
            dialCode: "+91",
          },
          action: "delete your account",
        }),
      });

      if (response.status === 200) {
        const responseJSON = await response.json();
        setSendOTPVerificationToken(responseJSON.verificationToken);
        setIsPhoneNumberEntered(true);
      } else {
        setSendOTPVerificationToken("");
        setSendOTPError(
          "We are unable to send OTP at this time. Please try again later."
        );
      }
    } catch (error) {
      setSendOTPVerificationToken("");
      setSendOTPError(
        "We are unable to send OTP at this time. Please try again later."
      );
    }
    setSendOTPProcessing(false);
  };

  const handleResendClick = async () => {
    await sendOTP();
    setIsOtpResent(true);
  };

  const handleVerifyClick = async () => {
    setVerifyOTPProcessing(true);
    setSendOTPError("");
    setVerifyOTPError("");
    try {
      const response = await fetch("https://api.linkyards.in/auth/verifyotp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: phoneNumber,
          country: {
            name: "India",
            code: "IN",
            dialCode: "+91",
          },
          verificationCode: verificationCode,
          verificationToken: sendOTPVerificationToken,
        }),
      });

      const responseJSON = await response.json();
      if (response.status === 200) {
        setVerifyOTPVerificationToken(responseJSON.verificationToken);
        setIsPhoneNumberEntered(true);
        setIsOtpVerified(true);
      } else {
        setVerifyOTPVerificationToken("");
        setVerifyOTPError(
          responseJSON.error ?? "The OTP you have entered is invalid."
        );
      }
    } catch (error) {
      setVerifyOTPVerificationToken("");
      setVerifyOTPError("The OTP you have entered is invalid.");
    }
    setVerifyOTPProcessing(false);
  };

  const handleDeleteAccount = async () => {
    setAccountDeleteProcessing(true);
    setSendOTPError("");
    setVerifyOTPError("");
    setAccountDeleteError("");
    try {
      const response = await fetch("https://api.linkyards.in/auth/request/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: phoneNumber,
          country: {
            name: "India",
            code: "IN",
            dialCode: "+91",
          },
          verificationToken: verifyOTPVerificationToken,
        }),
      });

      const responseJSON = await response.json();
      if (response.status === 200) {
        setVerifyOTPVerificationToken(responseJSON.verificationToken);
        setIsPhoneNumberEntered(true);
        setIsOtpVerified(true);
        setIsRequestProcessed(true);
      } else {
        setVerifyOTPVerificationToken("");
        setAccountDeleteError(
          responseJSON.error ??
            "Failed to delete account. Please try again later."
        );
      }
    } catch (error) {
      setVerifyOTPError("Failed to delete account. Please try again later.");
    }
    setAccountDeleteProcessing(false);
  };

  const handleRestartProcess = () => {
    setIsRequestProcessed(false);
    setIsPhoneNumberEntered(false);
    setIsOtpVerified(false);
    setPhoneNumber("");
    setVerificationCode("");
    setSendOTPError("");
    setVerifyOTPError("");
    setSendOTPVerificationToken("");
    setVerifyOTPVerificationToken("");
  };

  return (
    <>
      <div className="header">
        <img src={LogoIcon} alt="Linkyards Logo" className="logo" />
        <span className="logo-title">Linkyards</span>
      </div>
      <div className="container">
        <h1 className="form-title">Account Deletion Request</h1>
        <div className="form-container">
          {isRequestProcessed ? (
            <div className="success-container">
              <img
                src={SuccessIcon}
                alt="request-processed"
                className="success-icon"
              />
              <br />
              <p>
                Your request for account deletion has been received successfully
                and is being processed. We will update you once it is processed.
              </p>
              <br />
              <button className="success-button" onClick={handleRestartProcess}>
                Done
              </button>
            </div>
          ) : (
            <>
              {!isOtpVerified && (
                <div>
                  {!isPhoneNumberEntered ? (
                    <div className="form">
                      <input
                        type="tel"
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                      />
                      <div className="errorMessage">{sendOTPError}</div>
                      <button
                        disabled={!isPhoneNumberValid || sendOTPProcessing}
                        className="next-button"
                        onClick={sendOTP}
                      >
                        {sendOTPProcessing ? "Sending..." : "Next"}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div className="input-container">
                        <div className="phone-number">
                          <span>{phoneNumber}</span>
                          <span
                            className="change-number-link"
                            onClick={handleRestartProcess}
                          >
                            Change Phone Number
                          </span>
                        </div>
                        <span>
                          <img
                            src={CheckIcon}
                            alt="valid-field"
                            className="valid-field"
                          />
                        </span>
                      </div>
                      <br />
                      <div className="input-container">
                        <input
                          type="text"
                          placeholder="Enter OTP"
                          value={verificationCode}
                          onChange={handleOtpChange}
                        />
                      </div>
                      <div className="errorMessage">{verifyOTPError}</div>
                      <div className="button-group">
                        <button
                          disabled={
                            isOtpResent ||
                            sendOTPProcessing ||
                            verifyOTPProcessing
                          }
                          className="resend-button"
                          onClick={handleResendClick}
                        >
                          {sendOTPProcessing
                            ? "Sending OTP..."
                            : isOtpResent
                            ? `Resend OTP in ${timer}s`
                            : "Resend OTP"}
                        </button>
                        <button
                          disabled={
                            !isVerificationCodeValid ||
                            sendOTPProcessing ||
                            verifyOTPProcessing
                          }
                          className="verify-button"
                          onClick={handleVerifyClick}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isOtpVerified && (
                <div>
                  <div className="input-container">
                    <div className="phone-number">
                      <span>{phoneNumber}</span>
                      <span
                        className="change-number-link"
                        onClick={handleRestartProcess}
                      >
                        Change Phone Number
                      </span>
                    </div>
                    <span>
                      <img
                        src={CheckIcon}
                        alt="valid-field"
                        className="valid-field"
                      />
                    </span>
                  </div>
                  <br />
                  <div className="input-container">
                    <div className="phone-number">
                      <span>{phoneNumber}</span>
                    </div>
                    <span>
                      <img
                        src={CheckIcon}
                        alt="valid-field"
                        className="valid-field"
                      />
                    </span>
                  </div>
                  <div className="errorMessage">{accountDeleteError}</div>
                  <div className="single-button">
                    <button
                      className="delete-account-button"
                      onClick={handleDeleteAccount}
                      disabled={accountDeleteProcessing}
                    >
                      {accountDeleteProcessing
                        ? "Processing..."
                        : "Request account deletion"}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default App;
